<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Modifica Account</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Modifica Account</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <form @submit="submit">
          <label>Nome:</label>
          <ion-input id="input" v-model="company.name"></ion-input>
          <label>Risorsa:</label>
          <ion-input id="input" v-model="company.consultant"></ion-input>
          <label>Luogo:</label>
          <ion-input id="input" v-model="company.place"></ion-input>
          <ion-button type="submit">MODIFICA</ion-button> 
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar
    },
  data: function(){
    return{
      company:{
          name: '',
          consultant:'',
          place:'',
      }
    }
  },
  methods:{
    submit(ev){
      ev.preventDefault();
      if(this.name!='' && this.consultant!='' && this.place!=''){
        this.axios.post(this.apiUrl + 'update-company', {company:this.company}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/companies-list');
            return toast.present();
          }
        })
      }
    },
    deleteCompany(){
      this.axios.post(this.apiUrl + 'delete-company', {company:this.company}, this.headers)
      .then(async (response) => {
        if(response.data.status_code==200){
          this.company=response.data.message              
        }
      })
    },
    getCompanyInfo(){
      this.axios.post(this.apiUrl + 'get-company-info', {id:this.$route.params.id}, this.headers)
      .then(async (response) => {
        this.company=response.data.company;
      })
    }
  },
  ionViewWillEnter: function(){
    this.getCompanyInfo();
  }
}
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
</style>